<template>
    <v-container class="pa-0 fill-height">
        <qrcode-stream :constraints="{ facingMode }" :camera="camera" @decode="onDecode" @error="onError"
            ref="qrcodeReader">

            <v-container class="fill-height"
                style="display: flex; flex-direction: column; align-items: center; justify-content: center;">

                <v-btn class="ma-0" @click="switchCamera" fab :disabled="scanning"
                    style="position:fixed; right:20px;top:70px;">
                    <v-icon>
                        mdi-camera-flip
                    </v-icon>
                </v-btn>

                <v-icon class="mt-10" size="240" color="rgb(255 255 255 / 28%)"
                    v-bind:style="{ animation: scanning ? 'rotation 1s infinite linear' : '' }">
                    {{ scanning ? 'mdi-loading' : 'mdi-scan-helper' }}
                </v-icon>


                <p class="text-center text-h5 mt-10" style="color:rgb(255 255 255 / 80%)">Escanea el QR de tu reserva.
                </p>


                <v-btn x-large class="mt-12 mb-12" rounded @click="focusCivilId" color="primary darken-2"
                    :disabled="scanning">
                    <v-icon left>
                        mdi-login
                    </v-icon>
                    Ingreso con identificación
                </v-btn>




            </v-container>

        </qrcode-stream>


        <v-bottom-sheet v-model="showCivilId" max-width="600">
            <v-card class="rounded-t-lg">
                <v-card-title>
                    Ingrese identificación
                    <v-spacer></v-spacer>
                    <v-btn fab icon small @click="showCivilId = false; scanning = false; civilId = null">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider class="mb-2"></v-divider>
                <v-form ref="civilIdForm" lazy-validation>
                    <v-card-text>
                        <v-text-field rounded v-model="civilId" filled label="Identificación" required id="civilID"
                            type="number" number :rules="[v => !!v || 'La identificación es requerida']"></v-text-field>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn class="rounded-lg mb-4" large
                            @click="showCivilId = false; scanning = false; civilId = null" rounded>CANCELAR</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn large color="primary" @click="validateAndLogin" rounded :loading="loading"
                            class="mb-4 rounded-lg">
                            <v-icon left>
                                mdi-login
                            </v-icon>
                            CONTINUAR
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-bottom-sheet>
        <v-dialog v-model="dialog" max-width="400">
            <v-card v-if="user" class="rounded-lg overflow-hidden ">
                <v-card-text class="text-center pa-6">
                    <!-- Success Icon Animation -->
                    <v-icon size="64" class="mb-4 success-icon" color="success">
                        mdi-check-circle
                    </v-icon>

                    <!-- Welcome Message -->
                    <h2 class="text-h4 font-weight-bold mb-2">
                        ¡Check-in exitoso!
                    </h2>
                    <p class="text-h5 mb-4">{{ user.displayName }}</p>


                    <!-- Check-in Time -->
                    <!--  <div class="my-4 pa-2">
            <v-icon left>mdi-clock-outline</v-icon>
            <span class="text-subtitle-1">
              {{ getCurrentTime() }}
            </span>
          </div> -->

                    <!-- Subscription Info -->
                    <template v-if="user && user.endOfSubscription">
                        <v-alert style="width:300px;" :color="getSubscriptionAlertColor(user.endOfSubscription)"
                            :icon="getSubscriptionIcon(user.endOfSubscription)"
                            class="subscription-info mb-0 mt-8 mx-auto" border="left" elevation="2" colored-border>
                            <div class="d-flex align-center">
                                <div>
                                    <div class="text-subtitle-1 font-weight-bold mb-1">
                                        Estado de suscripción
                                    </div>
                                    <div class="text-body-2">
                                        {{ getSubscriptionStatus(user.endOfSubscription) }}
                                    </div>
                                    <div class="text-caption mt-1">
                                        Vence: {{ formatEndDate(user.endOfSubscription) }}
                                    </div>
                                </div>
                            </div>
                        </v-alert>
                    </template>



                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import { getFirestore, collection, query, where, getDocs, setDoc, doc, getDoc, addDoc, deleteDoc, updateDoc, runTransaction, Timestamp, serverTimestamp } from "firebase/firestore";

import { getDatabase, ref, push, set, get, child, onChildChanged, update } from "firebase/database";
import CryptoJS from 'crypto-js';

import moment from 'moment';
import { watch } from 'vue';

import { logAuditEvent } from '@/error/audit.js';
///        await logAuditEvent('update',this.$store.state.Auth.token.claims.user_id,`User ${this.user.id} re-enabled`)
import UserImage from './profile/UserImage.vue';
export default {
    beforeDestroy() {
        this.stopCamera();
    },
    deactivated() {
        this.stopCamera();
    },
    beforeRouteLeave(to, from, next) {
        this.stopCamera();
        next();
    },
    name: 'QRCodeReader',
    components: {
        QrcodeStream,
        UserImage
    },
    props: {

        selectedSchedule: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            civilId: null,
            showCivilId: false,
            cameraStream: null,
            cameraReady: false,
            scanning: false,
            lastScanned: null,
            dialog: false,
            hideFinish: false,
            workoutsCompleted: 0,
            workoutDates: [],
            workoutDoc: null,
            workingOut: false,
            user: null,
            inscription: null,
            today: moment().format("YYYY-MM-DD"),
            alert: false,
            loading: false,
            cameras: [],
            selectedCameraIndex: 0,
            selectedCamera: null,
            facingMode: 'user',
            camera: null,
            successTimeout: null
        }
    },
    mounted() {
        this.fetchCameras();
    },
    methods: {
        async fetchCameras() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                this.cameras = devices.filter(device => device.kind === 'videoinput');

                if (this.cameras.length > 0) {
                    this.camera = this.facingMode === 'user' ? 'front' : 'rear';

                    // Store stream reference for cleanup
                    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                    this.cameraStream = stream;
                }
            } catch (error) {
                console.error('Error fetching cameras:', error);
            }
        },
        switchCamera() {
            if (this.cameras.length > 0) {
                this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
                this.camera = this.facingMode === 'user' ? 'front' : 'rear';
            }
        },
        focusCivilId() {
            this.showCivilId = true;
            setTimeout(() => {
                const input = document.getElementById("civilID");
                if (input) {
                    input.focus();
                }
                if (this.$refs.civilIdForm) {
                    this.$refs.civilIdForm.resetValidation();
                }
            }, 300);
        },
        onError(err) {
            const cameraMissingError = error.name === 'OverconstrainedError'
            const triedFrontCamera = this.facingMode === 'user'

            if (triedFrontCamera && cameraMissingError) {
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'No se encontró la cámara frontal.',
                    type: 'error'
                });
            }
            let error = `[${err.name}]: `

            if (err.name === 'NotAllowedError') {
                error += 'you need to grant camera access permission'
            } else if (err.name === 'NotFoundError') {
                error += 'no camera on this device'
            } else if (err.name === 'NotSupportedError') {
                error += 'secure context required (HTTPS, localhost)'
            } else if (err.name === 'NotReadableError') {
                error += 'is the camera already in use?'
            } else if (err.name === 'OverconstrainedError') {
                error += 'installed cameras are not suitable'
            } else if (err.name === 'StreamApiNotSupportedError') {
                error += 'Stream API is not supported in this browser'
            } else if (err.name === 'InsecureContextError') {
                error += 'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
            } else {
                error += err.message
            }
            console.log(error)
        },
        async getReservations(qrParsed) {
            try {
                const userid = qrParsed.userId;
                const reservationId = qrParsed.date;
                const db = getDatabase();

                let refer = ref(db, `${userid}/reservations`)
                let snapshot = await get(refer);
                if (snapshot.exists()) {
                    let reservations = snapshot.val();
                    for (const key in reservations) {
                        if (Object.hasOwnProperty.call(reservations, key)) {
                            const reservation = reservations[key];
                            if (reservation.date == qrParsed.date) {

                                await this.getUserById(userid)
                                await this.getWorkoutsCompleted(userid)
                                await this.updateReservation(qrParsed, key)
                                this.lastScanned = reservation
                                this.inscription = reservation

                                await this.finishDailyWorkout(userid);
                                this.showSuccessDialog();
                                return;
                            }

                        }
                    }

                } else {
                    //console.log("No data available");
                }
            } catch (error) {
                console.error("Error getting reservations:", error);
                this.checkConnectivityError(error);
                throw error;
            }
        },
        async updateReservation(qrParsed, idReservation) {
            try {
                const db = getDatabase();
                const userid = qrParsed.userId;
                const reservationRef = ref(db, `${userid}/reservations/${idReservation}`);

                // Get current reservation data
                const snapshot = await get(reservationRef);
                if (!snapshot.exists()) return false;

                const reservation = snapshot.val();

                // Handle different date formats
                let date;
                if (reservation.date?.seconds) {
                    date = moment(reservation.date.seconds * 1000);
                } else if (typeof reservation.date === 'string') {
                    date = moment(reservation.date, "YYYY-MM-DD HH:mm:ss");
                } else {
                    return false;
                }

                // Validate reservation date
                if (!moment().isSame(date, 'day')) {
                    this.$notify({
                        group: 'feedback',
                        title: 'Error',
                        text: 'Reservación no válida para hoy.',
                        type: 'warning'
                    });
                    return false;
                }

                // Check if already used
                if (reservation.used) {
                    this.$notify({
                        group: 'feedback',
                        title: 'Atención',
                        text: 'Ya se registró asistencia hoy.',
                        type: 'warning'
                    });
                    return false;
                }

                // Update used status
                reservation.used = true;
                await update(reservationRef, reservation);

                this.$notify({
                    group: 'feedback',
                    title: 'Éxito',
                    text: this.showCivilId ? 'Asistencia registrada correctamente.' : 'Reservación confirmada correctamente.',
                    type: 'success'
                });

                this.showCivilId = false;
                return true;

            } catch (error) {
                console.error("Error updating reservation:", error);
                this.checkConnectivityError(error);
                return false;
            }
        },

        async onDecode(code) {
            try {
                this.scanning = true;
                // Close any existing success dialog to show loading state
                this.dialog = false;
                this.lastScanned = null;

                const decryptedData = CryptoJS.AES.decrypt(code, 'YOUSHOULDSAYNOTOJACK~!@#$%^&*()_+');
                let qrParsed = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));

                let today = moment();
                let reservationDate = moment(qrParsed.date, "YYYY-MM-DD HH:mm:ss");

                if (!today.isSame(reservationDate, 'day')) {
                    this.$notify({
                        group: 'feedback',
                        title: 'Error',
                        text: 'Codigo QR no válido para hoy.',
                        type: 'warning'
                    });
                } else {
                    await this.getReservations(qrParsed);
                }

            } catch (error) {
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Codigo QR invalido' + error,
                    type: 'error'
                });
            } finally {
                // Reset scanning immediately
                this.scanning = false;
            }
        },
        async inscriptAndFinishDailyWorkout(id) {
            try {
                this.hideFinish = true;
                this.workingOut = false;

                const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');
                const currentTime = moment();

                // Buscar la clase actual o próxima
                const db = getFirestore();
                const date = moment().format("YYYY-MM-DD");

                // Buscar clases desde 1 hora antes hasta 1 hora después
                const schedulesRef = collection(db, `schedule/${date}/schedules`);
                const q = query(schedulesRef,
                    where("startDate", ">", currentTime.clone().subtract(1, 'hour').toDate()),
                    where("startDate", "<", currentTime.clone().add(1, 'hour').toDate())
                );

                const schedules = await getDocs(q);

                if (schedules.empty) {
                    this.$notify({
                        group: 'feedback',
                        title: 'Atención',
                        text: 'No hay horarios disponibles en este momento.',
                        type: 'warning'
                    });
                    return false;
                }

                // Encontrar la clase más apropiada (actual o próxima más cercana)
                const sortedSchedules = schedules.docs
                    .map(doc => ({
                        ...doc.data(),
                        id: doc.id,
                        startMoment: moment(doc.data().startDate)
                    }))
                    .sort((a, b) => Math.abs(moment(a.startDate).diff(currentTime)) - Math.abs(moment(b.startDate).diff(currentTime)));

                const selectedSchedule = sortedSchedules[0];

                if (!selectedSchedule || selectedSchedule.spots <= 0) {
                    this.$notify({
                        group: 'feedback',
                        title: 'Atención',
                        text: 'No hay cupos disponibles en este momento.',
                        type: 'warning'
                    });
                    return false;
                }

                // Verificar si ya entrenó hoy
                let found = this.workoutDates.find(elem =>
                    elem.substring(0, 10) == formattedDate.substring(0, 10)
                );

                if (found) {
                    this.$notify({
                        group: 'feedback',
                        title: 'Atención',
                        text: 'Ya se registró asistencia hoy.',
                        type: 'warning'
                    });
                    return false;
                }

                // Registrar en la clase
                const scheduleRef = doc(db, `schedule/${date}/schedules/${selectedSchedule.id}`);
                await updateDoc(scheduleRef, {
                    spots: selectedSchedule.spots - 1,
                    users: [...selectedSchedule.users, {
                        id: id,
                        checkedIn: moment().format("YYYY-MM-DD HH:mm:ss")
                    }]
                });

                // Registrar el workout
                const workoutsRef = collection(db, 'workouts');
                await addDoc(workoutsRef, {
                    user_id: id,
                    date: serverTimestamp(),
                    mainWorkout: this.user?.mainWorkout || null,
                    rating: 0,
                    workoutsCompleted: this.workoutsCompleted + 1
                });

                this.workoutsCompleted += 1;

                // Registrar alerta de check-in sin reserva
                await this.setAlert('checkin_no_reservation', moment().toDate(), null);

                this.$notify({
                    group: 'feedback',
                    title: 'Éxito',
                    text: 'Check-in registrado correctamente. Recuerda reservar para próximas clases!',
                    type: 'success'
                });

                this.showCivilId = false;
                return true;

            } catch (error) {
                console.error("Error in inscriptAndFinishDailyWorkout:", error);
                this.checkConnectivityError(error);
                throw error;
            }
        },
        async finishDailyWorkout(id) {
            try {
                this.hideFinish = true;
                this.workingOut = false;

                const db = getFirestore();
                const workoutsRef = collection(db, 'workouts');

                // Add new workout document without workoutNumber
                await addDoc(workoutsRef, {
                    user_id: id,
                    date: serverTimestamp(),
                    mainWorkout: this.user?.mainWorkout || null,
                    rating: 0,
                    workoutsCompleted: this.workoutsCompleted + 1
                });

                this.workoutsCompleted += 1;
                await this.updateCheckIn(id, this.inscription);

                if (this.user?.plan && this.user.plan <= this.workoutsCompleted) {
                    this.$notify({
                        group: 'feedback',
                        title: 'Atención',
                        text: 'Plan completado!! Continua asi!!',
                        type: 'success'
                    });
                }
            } catch (error) {
                console.error("Error in finishDailyWorkout:", error);
                this.checkConnectivityError(error);
                throw error;
            }
        },
        async updateCheckIn(id, schedule) {
            const user = id
            let date;
            if (this.inscription && schedule) {
                date = moment(schedule.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")

                // Get a reference to the schedule document
                const db = getFirestore()
                let scheduleRef = doc(db, `schedule/${date}/schedules/${schedule.id}`);

                await runTransaction(db, async transaction => {
                    const doc = await transaction.get(scheduleRef);
                    if (!doc.exists) {
                        throw "Document does not exist!";
                    }
                    // Decrement the spots and add thge user to the users array
                    const data = doc.data();
                    let userInscription = data.users.find(elem => elem.id == user)
                    userInscription.checkedIn = moment().format("YYYY-MM-DD HH:mm:ss")

                    transaction.update(scheduleRef, data);
                });

                return true
            } else {
                const db = getFirestore()
                let startDate;
                let q;
                let schedules;

                date = moment().format("YYYY-MM-DD")
                startDate = moment().subtract(1, 'hours').format("YYYY-MM-DD HH:mm:ss")
                const schedulesRef = collection(db, `schedule/${date}/schedules`);
                q = query(schedulesRef, where("startDate", ">", startDate));
                schedules = await getDocs(q);


                if (schedules.empty) {
                    this.$notify({
                        group: 'feedback',
                        title: 'Atención',
                        text: 'No hay horarios disponibles para el día de hoy.',
                        type: 'warning'
                    });
                    return false
                }
                let schedule;
                schedule = {
                    ...schedules.docs[0].data(),
                    id: schedules.docs[0].id
                }

                let userInscriptionObj = {
                    id: user,
                    checkedIn: moment().format("YYYY-MM-DD HH:mm:ss")
                }

                let scheduleDate;


                if (schedule.startDate && schedule.startDate.seconds) {
                    scheduleDate = moment(schedule.startDate.seconds * 1000).format("YYYY-MM-DD")
                } else {

                    scheduleDate = moment(schedule.startDate, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
                }
                //here use a transaction to update and not updatedoc

                let scheduleRef = doc(db, `schedule/${scheduleDate}/schedules/${schedule.id}`);

                await runTransaction(db, async transaction => {
                    const doc = await transaction.get(scheduleRef);
                    if (!doc.exists) {
                        throw "Document does not exist!";
                    }
                    // Decrement the spots and add thge user to the users array

                    const data = doc.data();

                    if (data.spots > 0) {
                        data.spots--;

                        //check if user is already in the array
                        let userInscription = data.users.find(elem => elem.id == user)
                        if (userInscription) {
                            userInscription.checkedIn = moment().format("YYYY-MM-DD HH:mm:ss")
                        } else {
                            data.users.push(userInscriptionObj)
                        }
                    } else {
                        this.$notify({
                            group: 'feedback',
                            title: 'Atención',
                            text: 'No hay cupos disponibles para el siguiente horario.',
                            type: 'warning'
                        });

                        this.setAlert('no_spots', moment().toDate(), null)

                        return false
                    }



                    transaction.update(scheduleRef, data);

                    this.$notify({
                        group: 'feedback',
                        title: 'Inscripción exitosa',
                        text: 'Recuerda reservar para mañana!!',
                        type: 'success'
                    });
                });

                return true

            }

        },
        async loginWithID() {
            try {
                //Reset all important values in data
                this.lastScanned = null;
                this.dialog = false;
                this.hideFinish = false;
                this.workingOut = false;
                this.inscription = null;
                this.workoutsCompleted = 0;
                this.workoutDates = [];
                this.workoutDoc = null;
                this.user = null;
                this.inscript = null;
                this.today = moment().format("YYYY-MM-DD");
                this.alert = false;

                this.loading = true;
                let id = this.civilId.replace(/\D/g, "");
                let ok = await this.getUserById(id)
                if (!ok) {
                    this.loading = false;
                    return;
                }
                ok = await this.getWorkoutsCompleted(id)
                if (!ok) {
                    this.loading = false;
                    return;
                }
                await this.getReservationsById(id)
                this.loading = false;

                logAuditEvent('checkin', this.$store.state.Auth.token.claims.user_id, `User ${id} checked in.`)

                this.showSuccessDialog();
                
            } catch (error) {
                logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `User ${id} checkin failed.`)
            }
        },
        async getReservationsById(id) {
            try {
                const userid = id;
                const db = getDatabase();
                const today = moment();

                // Get reservations
                const reservationsRef = ref(db, `${userid}/reservations`);
                const snapshot = await get(reservationsRef);

                if (snapshot.exists()) {
                    const reservations = snapshot.val();

                    // Find today's reservation
                    for (const [key, reservation] of Object.entries(reservations)) {
                        // Handle different date formats
                        let reservationDate;
                        if (reservation.date?.seconds) {
                            // Handle Firestore timestamp
                            reservationDate = moment(reservation.date.seconds * 1000);
                        } else if (typeof reservation.date === 'string') {
                            // Handle string date format
                            reservationDate = moment(reservation.date, "YYYY-MM-DD HH:mm:ss");
                        } else {
                            continue; // Skip invalid date formats
                        }

                        if (reservationDate.isSame(today, 'day')) {
                            console.log("Found matching reservation:", reservation);
                            this.lastScanned = reservation;
                            this.inscription = reservation;

                            // Update reservation status
                            const success = await this.updateReservation({
                                userId: id,
                                date: reservation.date
                            }, key);

                            if (success) {
                                await this.finishDailyWorkout(id);
                                this.dialog = true;
                                return; // Exit after handling existing reservation
                            }
                        }
                    }
                }

                // If we get here, no valid reservation was found - handle walk-in
                if (this.user.plan > this.workoutsCompleted) {
                    await this.inscriptAndFinishDailyWorkout(id);
                } else {
                    this.$notify({
                        group: 'feedback',
                        title: 'Atención',
                        text: 'Plan completado, no se puede registrar mas asistencias esta semana.',
                        type: 'warning'
                    });
                    await this.setAlert('plan_completed', today.toDate(), null);
                }

            } catch (error) {
                console.error("Error in getReservationsById:", error);
                this.checkConnectivityError(error);
                throw error;
            }
        },
        getStartOfWeek() {
            const now = new Date();
            let day = now.getDay();
            const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
            const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff);
            return startOfWeek;
        },
        getEndOfWeek() {
            const startOfWeek = this.getStartOfWeek();
            const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
            return endOfWeek;
        },
        async getWorkoutsCompleted(id) {
            try {
                const db = getFirestore()
                const workoutsRef = collection(db, 'workouts');
                const startOfWeek = this.getStartOfWeek();
                const endOfWeek = this.getEndOfWeek();

                // Query workouts for this user in current week
                const q = query(workoutsRef,
                    where("user_id", "==", id),
                    where("date", ">=", startOfWeek),
                    where("date", "<", endOfWeek)
                );

                const workoutDocs = await getDocs(q);

                // Get workout dates and count
                this.workoutDates = workoutDocs.docs.map(doc => {
                    const data = doc.data();
                    return moment(data.date.seconds * 1000).format('YYYY-MM-DD HH:mm:ss');
                });

                this.workoutsCompleted = this.workoutDates.length;

                const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');

                // Add null check for this.user and this.user.plan
                if (this.user && this.user.plan && this.user.plan <= this.workoutsCompleted) {
                    this.$notify({
                        group: 'feedback',
                        title: 'Atención',
                        text: 'Plan completado, no se puede registrar mas asistencias.',
                        type: 'warning'
                    });

                    await this.setAlert('plan_completed', moment().toDate(), null)
                    return false;
                }

                // Check for same day workout
                if (this.workoutDates.length > 0) {
                    const lastWorkoutDate = this.workoutDates[this.workoutDates.length - 1];
                    const isSameDay = moment(lastWorkoutDate).isSame(moment(formattedDate), 'day');

                    if (isSameDay) {
                        this.$notify({
                            group: 'feedback',
                            title: 'Atención',
                            text: 'Ya se registro asistencia hoy.',
                            type: 'warning'
                        });
                        return false;
                    }
                }

                this.workingOut = true;
                return true;
            } catch (error) {
                console.error("Error getting workouts completed:", error);
                this.checkConnectivityError(error);
                return false;
            }
        },
        async getUserById(id) {
            try {
                const db = getFirestore()
                let user = id

                const docRef = doc(db, `users/${user}`);
                const docSnap = await getDoc(docRef);

                if (!docSnap.exists()) {
                    this.$notify({
                        group: 'feedback',
                        title: 'Error',
                        text: 'Usuario no encontrado',
                        type: 'error'
                    });

                    this.setAlert('user_not_found', moment().toDate(), null)
                    return false;
                }

                let data = docSnap.data()
                data.id = docSnap.id

                // Ensure plan is converted to number and has a default value
                data.plan = data.plan ? parseInt(data.plan) : 6;

                // Set default plan if undefined or 0
                if (!data.plan || data.plan === 0) {
                    data.plan = 6;
                }

                this.user = data;

                if (this.user.endOfSubscription) {
                    let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000)
                    let today = moment().toDate()
                    if (endOfSubscription < today) {
                        await this.setAlert('user_subscription_expired', today, null)
                        this.$notify({
                            group: 'feedback',
                            title: 'Atención',
                            text: 'Plan vencido, no se puede registrar asistencia. Contacte con el administrador.',
                            type: 'warning'
                        });
                        return false;
                    } else {
                        //calculate diffs and if less than 5 days, show alert set this.alert=true
                        let diff = endOfSubscription.getTime() - today.getTime();
                        let days = Math.ceil(diff / (1000 * 3600 * 24));
                        if (days <= 5) {
                            this.alert = days;
                        } else {
                            this.alert = false;
                        }



                    }
                }


                let today = moment().toDate()

                //check if user is on an active licsense, for this it needs to check the user 'licensePaused' boolean property.
                if (this.user.licensePaused) {
                    //await this.setAlert('user_license_paused', today, null)
                    this.$notify({
                        group: "feedback",
                        title: "Error",
                        type: "error",
                        text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia. De lo contrario contacta con el administrador.",
                    });

                    this.setAlert('user_license_paused', today, null)
                    return false;
                }

                //and also search in the user 'licenses' collection for license between issuedOn and resumedOn datess.
                // Query Firestore for licenses issued before today
                const licensesRef = collection(db, `users/${user}/licenses`);
                const q = query(licensesRef, where("issuedOn", "<", today));

                const licenseDocs = await getDocs(q);
                const filteredLicenses = licenseDocs.docs
                    .map(doc => doc.data())
                    .filter(license => {
                        if (license.resumedOn) {
                            return new Date(license.resumedOn.seconds * 1000) > today;
                        } else if (typeof license.resumedOn === 'undefined') {
                            return true;
                        }

                    }); // Filter by resumedOn in client

                if (filteredLicenses.length === 0) {
                    //console.log('No matching documents.');
                } else {
                    this.$notify({
                        group: "feedback",
                        title: "Error",
                        type: "error",
                        text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia.",
                    });


                    this.setAlert('user_license_paused', today, null)
                    return false;
                }




                return true;
            } catch (error) {
                console.error("Error in getUserById:", error);
                this.checkConnectivityError(error);
                throw error;
            }
        },
        async setAlert(type, date, description) {
            const db = getFirestore();
            
            try {
                // More robust user ID determination
                let userID = null;
                if (this.user?.id) {
                    userID = this.user.id;
                } else if (this.civilId) {
                    userID = this.civilId.replace(/\D/g, ""); // Clean non-numeric chars
                }

                // Validate we have a user ID before proceeding
                if (!userID) {
                    console.error("Cannot set alert: No user ID available");
                    await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
                        `Failed to set alert - missing user ID for type: ${type}`
                    );
                    return;
                }

                const timestampDate = Timestamp.fromDate(date);

                const newAlert = {
                    user_id: userID,
                    type: type,
                    date: timestampDate,
                    description: description,
                    seen: false,
                    path: 'QRreader2'
                };

                await addDoc(collection(db, 'alerts'), newAlert);
                
                await logAuditEvent('create', this.$store.state.Auth.token.claims.user_id,
                    `Created alert type ${type} for user ${userID}`);

            } catch (error) {
                console.error("Error adding alert:", error);
                await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
                    `Error adding alert for user ${userID || 'unknown'}: ${error.message}`
                );
            }
        },
        checkConnectivityError(error) {
            // Check common Firebase offline/connectivity error codes
            if (error.code === "unavailable" ||
                error.code === "auth/network-request-failed" ||
                error.message?.includes('network') ||
                !navigator.onLine) {

                this.$notify({
                    group: "feedback",
                    duration: 5000,
                    type: "error",
                    title: "Sin conexión",
                    text: "No hay conexión a internet. Por favor verifica tu conexión e intenta nuevamente."
                });
                return true;
            }
            return false;
        },
        getSubscriptionStatus(endDate) {
            const end = moment(new Date(endDate.seconds * 1000));
            const daysLeft = end.diff(moment(), 'days');

            if (daysLeft < 0) {
                return 'Suscripción vencida';
            } else if (daysLeft === 0) {
                return 'Último día de suscripción';
            } else if (daysLeft <= 5) {
                return `¡Atención! ${daysLeft} días restantes`;
            } else {
                return `${daysLeft} días restantes de suscripción`;
            }
        },
        getCurrentTime() {
            return moment().format('HH:mm:ss');
        },
        showSuccessDialog() {
            this.dialog = true;

            // Clear previous timeout if exists
            if (this.successTimeout) {
                clearTimeout(this.successTimeout);
            }

            // Store new timeout reference
            this.successTimeout = setTimeout(() => {
                this.dialog = false;
            }, 3000);
        },
        getSubscriptionAlertColor(endDate) {
            const end = moment(new Date(endDate.seconds * 1000));
            const daysLeft = end.diff(moment(), 'days');

            if (daysLeft < 0) {
                return 'error';
            } else if (daysLeft <= 5) {
                return 'warning';
            } else {
                return 'success';
            }
        },
        getSubscriptionIcon(endDate) {
            const end = moment(new Date(endDate.seconds * 1000));
            const daysLeft = end.diff(moment(), 'days');

            if (daysLeft < 0) {
                return 'mdi-alert-circle';
            } else if (daysLeft <= 5) {
                return 'mdi-alert';
            } else {
                return 'mdi-check-circle';
            }
        },
        formatEndDate(endDate) {
            return moment(new Date(endDate.seconds * 1000)).format('DD/MM/YYYY');
        },
        async validateAndLogin() {
            if (this.$refs.civilIdForm && this.$refs.civilIdForm.validate()) {
                await this.loginWithID();
            }
        },
        stopCamera() {
            // Stop any active media tracks
            if (this.cameraStream) {
                this.cameraStream.getTracks().forEach(track => {
                    track.stop();
                    track.enabled = false;
                });
                this.cameraStream = null;
            }

            // Stop any active media devices
            navigator.mediaDevices?.getUserMedia({ video: true })
                .then(stream => {
                    stream.getTracks().forEach(track => {
                        track.stop();
                        track.enabled = false;
                    });
                })
                .catch(() => { /* Ignore errors during cleanup */ });

            const qrcodeStream = this.$refs.qrcodeStream;
            if (qrcodeStream) {
                // Access the internal video element of the QrcodeStream
                const videoElement = qrcodeStream.$el.querySelector('video');

                if (videoElement && videoElement.srcObject) {
                    const tracks = videoElement.srcObject.getTracks();
                    tracks.forEach((track) => track.stop());
                    videoElement.srcObject = null; // Clear the stream
                }
            }
        }
    }
}
</script>

<style scoped>
.v-input {
    flex: none !important;
}
</style>

<style>
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.qrcode-stream-camera {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    height: 100dvh !important;
}

.success-card {
    border-radius: 16px;
    overflow: hidden;
    background: linear-gradient(135deg, #4CAF50 0%, #2E7D32 100%);
    color: white;
}

.success-icon {
    animation: scaleIn 0.5s ease-out;
}

.subscription-info,
.time-info {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    backdrop-filter: blur(10px);
}

@keyframes scaleIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.v-card-text {
    animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.subscription-info {
    background: rgba(255, 255, 255, 0.15) !important;
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
}

.subscription-info:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.subscription-info .v-alert__content {
    width: 100%;
}
</style>
